<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-row>

                                    <a-col :span="20">
                                     
                                    </a-col>     
                                    <!-- <a-col :span="4" align='right'>
                                        <a-button icon='plus' type="primary" @click="Detail(0,0)"> 增加互动</a-button>
                                    </a-col> -->
                                </a-row>

                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="action" slot-scope="text, record">
                                <a v-if="record.PublishStatus==1" @click="Detail(record.ID,0)">编辑</a>
                                <a-divider v-if="record.PublishStatus==1" type="vertical" />
                                <a @click="showWinningDetail(record.ID)">明细</a>
                                <a-divider type="vertical" />
                                <a @click="showHelpAndTeamPersonalData(record.ID)">排行</a>
                                <a-divider v-if="record.PublishStatus==1" type="vertical" />
                                <a v-if="record.PublishStatus==1" @click="Update(record.ID,2)">结束</a>
                                <a-divider type="vertical" />
                                <a @click="ExportRechargeAwardRecord(record.ID)">导出</a>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "互动名称",
                        dataIndex: "Name",
                        width: 300,
                        ellipsis: true
                    },
                 
                  
                    {
                        title: "创建时间",
                        width:200,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy年MM月dd日')
                        }
                    },
                    {
                        title: "互动时间",
                        width: 300,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.BeginTime, 'yyyy年MM月dd日') + "-" + util.TimeStamptoDateTime(item.EndTime, 'yyyy年MM月dd日')
                        }
                    },
                  
                    {
                        title: "参与人数",
                        dataIndex: "JoinPersonelCount",
                        width: 100,
                        ellipsis: true
                    },
                      {
                        title: "访问人数",
                        dataIndex: "VisitPersonelCount",
                        width: 100,
                        ellipsis: true
                    },
                    {
                        title: "充值金额",
                        dataIndex: "RechargeMoney",
                        width: 150,
                        ellipsis: true
                    },
                    {
                        title: "账户余额",
                        dataIndex: "AccountBalanceMoney",
                        width: 150,
                        ellipsis: true
                    },
                    {
                        title: "状态",
                        width: 150,
                        customRender: (text, item) => {
                            var rt;
                            switch (item.PublishStatus) {
                                case 0:
                                    rt = "未生效";
                                    break;
                                case 1:
                                    rt = "已生效";
                                    break;
                                case 2:
                                    rt = "已结束";
                                    break;
                            }
                            return rt;
                        }
                    },

                    {
                        title: '操作',
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1",
                States: 0,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            //导出摇一摇活动的中奖记录
            ExportRechargeAwardRecord(id){
                var op = {
                    url: "/MarketingModule/Promote/EcportTurnTabelRechargeRecord",
                    data: {
                        TurnID: id,
                        isOffline: false//  正常导出
                    },//下载接口参数
                    FileName: '摇一摇中奖记录.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            Detail(ID, disabled) {
                var self = this;
                self.$router.push({
                    name: "InteractionConfig_AddInteraction",
                    query: { ID: ID, disabled: disabled }
                });
            },

            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/InteractionConfigList",
                    data: {
                        PageIndex: self.pagination.current,
                        States: self.PromotionType,
                    },
                    OnSuccess: function (data) {
                           data.data.Data.forEach(element => {
                               element.RechargeMoney = parseFloat(element.RechargeMoney).toFixed(2);
                               element.AccountBalanceMoney = parseFloat(element.AccountBalanceMoney).toFixed(2);
                        });
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },

            Update(ID, e) {
                var self = this;
                var con = e != 2 ? '发布' : '结束';
                this.$confirm({
                    title: '提示',
                    content: '是否确定' + con,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Promote/UpdateTurnPublishStatus",
                            data: {
                                ID: ID,
                                PublishStatus: e
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.$message.success(con + "成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },

            showWinningDetail: function (ID) {
                var self = this;
                self.$router.push({
                    name: "InteractionConfig_DetailInfo",
                    query: { TurnID: ID }
                });
            },
            showHelpAndTeamPersonalData: function (ID) {
                var self = this;
                self.$router.push({
                    name: "InteractionConfig_HelpAndTeamPersonalInfo",
                    query: { TurnID: ID }
                });
            },

          
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },

        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>